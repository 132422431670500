import service from "@/utils/request";
import dmsService from "@/utils/dmsrequest";

// 进度接口 后端还没提供


export function getOrderDetail(data) {
  return dmsService({
    url: "agentReturnOrderProcessDetail",
    data
  });
}